'use client';

import { LoginFeature } from '@/features/login/LoginFeature';
import MainLayout from '@repo-ui/layout/MainLayout';

/**
 * 로그인 페이지
 */
export default function LoginPage() {
  return (
    <MainLayout hideLoginButton>
      <LoginFeature />
    </MainLayout>
  );
}
