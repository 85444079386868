import { ButtonBase, type ButtonBaseProps } from '@mui/material';
import clsx from 'clsx';
import { toast } from 'react-toastify';

const clientId = 'Ov23lihLW0MNBn4Gi5yO';
const redirectUri = 'https://ttm.jjfive.net/oauth2/callback/github';

function buildGithubAuthUrl() {
  const backUrl = encodeURIComponent(document.location.href);
  const redirect = `${redirectUri}?backUrl=${backUrl}`;
  // const githubAuthUrl = `https://github.com/login/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=user`;
  return `https://github.com/login/oauth/authorize?client_id=${clientId}&redirect_uri=${redirect}&scope=user`;
}

export function GithubOauth2LoginButton(props: Omit<ButtonBaseProps, 'onClick'>) {
  const { className, ...rest } = props;

  return (
    <ButtonBase
      {...rest}
      className={clsx('GithubOauth2LoginButton-root', className)}
      onClick={() => {
        toast.warn('소셜 로그인 지원 예정');
        // window.location.href = buildGithubAuthUrl();
      }}
    />
  );
}
