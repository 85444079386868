import { ButtonBase, type ButtonBaseProps } from '@mui/material';
import type { UserAccount } from '@ocode/domain';
import { lodashGet } from '@ocodelib/util/misc';
import { useGoogleLogin } from '@react-oauth/google';
import clsx from 'clsx';
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { LoginProfileLoadedEvent, LoginTokenStorage } from '../../auth/authentication';
import { config } from '../../config';
import { routerPush } from '../../router';
import { handleError } from '../../util/handle-error';

export function GoogleOauth2LoginButton(props: Omit<ButtonBaseProps, 'onClick'>) {
  const { className, ...rest } = props;
  const [loading, setLoading] = useState(false);

  const doVerifyToken = useCallback(async (accessToken: string): Promise<boolean> => {
    setLoading(true);
    try {
      const result = await verifyToken(accessToken);
      LoginTokenStorage.value = result.accessToken;
      LoginProfileLoadedEvent.emit({ ...result.profile });
      return true;
    } finally {
      setLoading(false);
    }
  }, []);

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      setLoading(true);
      try {
        doVerifyToken(tokenResponse.access_token);
        routerPush('/');
      } catch (err) {
        handleError(err);
      } finally {
        setLoading(false);
      }
    },
    onError: (err) => {
      console.log('error=', err);
      handleError(err);
    },
  });

  return (
    <ButtonBase
      {...rest}
      className={clsx('GoogleOauth2LoginButton-root', className)}
      onClick={() => {
        toast.warn('소셜 로그인 지원 예정');
        // googleLogin();
      }}
    />
  );
}

async function verifyToken(token: string): Promise<{
  accessToken: string;
  profile: UserAccount;
}> {
  console.log('google login Success:', token);

  // ex)
  // clientId : "380921xxxx"
  // credential : "eyJhbGciOiJSUzI1NiIsImtpZCIxxxx"
  // select_by : "btn_confirm"

  // 서버로 토큰을 보냅니다.
  const data = await fetch(config.apiBaseURL + '/p/api/oauth2/verify/google', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ token }),
  }).then((res) => res.json());

  const accessToken = lodashGet(data, 'body.accessToken') as string | undefined;
  if (!accessToken) {
    throw new Error('unknown error: accessToken is null');
  }

  const profile = lodashGet(data, 'body.profile') as UserAccount | undefined;
  if (!profile) {
    throw new Error('unknown error: profile is null');
  }

  return { accessToken, profile };
}
