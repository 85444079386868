import FacebookLogin, {
  type FailResponse,
  type SuccessResponse,
} from '@greatsumini/react-facebook-login';
import { Box, ButtonBase, LinearProgress, type ButtonBaseProps } from '@mui/material';
import type { UserAccount } from '@ocode/domain';
import { lodashGet } from '@ocodelib/util/misc';
import clsx from 'clsx';
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { LoginProfileLoadedEvent, LoginTokenStorage } from '../../auth/authentication';
import { config } from '../../config';
import { routerPushI18n } from '../../router';
import { handleError } from '../../util/handle-error';

export function FacebookOauth2LoginButton(props: Omit<ButtonBaseProps, 'onClick'>) {
  const { className, ...rest } = props;
  const [loading, setLoading] = useState(false);

  const doVerifyToken = useCallback(async (accessToken: string, userId: string): Promise<void> => {
    setLoading(true);
    try {
      const result = await verifyToken(accessToken, userId);
      LoginTokenStorage.value = result.accessToken;
      LoginProfileLoadedEvent.emit({ ...result.profile });
    } finally {
      setLoading(false);
    }
  }, []);

  const handleSuccess = async (response: SuccessResponse) => {
    console.log('Login Success!', response);
    setLoading(true);
    try {
      await doVerifyToken(response.accessToken, response.userID);
      routerPushI18n('/');
      toast.success('Login success!');
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleFailure = (err: FailResponse) => {
    // 보통 로그인 취소했을때 호출됨
    console.log('Login Failed!', err);
    // toast.warn("login failed:" + err.status);
  };

  return (
    <>
      <FacebookLogin
        appId="767477028861573"
        onSuccess={handleSuccess}
        onFail={handleFailure}
        scope="public_profile" // email은 왜 안되나
        useRedirect={false}
        initParams={{
          version: 'v20.0' as any,
          localStorage: false,
        }}
        onProfileSuccess={(response) => {
          console.log('Get Profile Success!', response);
        }}
        render={({ onClick, logout }) => (
          <ButtonBase
            {...rest}
            className={clsx('FacebookOauth2LoginButton-root', className)}
            onClick={onClick}
          />
        )}
      />
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
          }}
        >
          <LinearProgress />
        </Box>
      )}
    </>
  );
}

async function verifyToken(
  token: string,
  userId: string,
): Promise<{
  accessToken: string;
  profile: UserAccount;
}> {
  console.log('facebook login Success:', token);

  // ex)
  // clientId : "380921xxxx"
  // credential : "eyJhbGciOiJSUzI1NiIsImtpZCIxxxx"
  // select_by : "btn_confirm"

  // 서버로 토큰을 보냅니다.
  const data = await fetch(config.apiBaseURL + '/p/api/oauth2/verify/facebook', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ token, userId }),
  }).then((res) => res.json());

  const accessToken = lodashGet(data, 'body.accessToken') as string | undefined;
  if (!accessToken) {
    throw new Error('unknown error: accessToken is null');
  }

  const profile = lodashGet(data, 'body.profile') as UserAccount | undefined;
  if (!profile) {
    throw new Error('unknown error: profile is null');
  }

  return { accessToken, profile };
}
