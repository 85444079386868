'use client';

import { Box, ButtonBase, Link, TextField, Typography } from '@mui/material';
import type { CoppiaPub } from '@ocode/domain';
import {
  FlexColumn,
  FlexRow,
  isEnterKeyEvent,
  isEnterOrTabKeyEvent,
  requestFocusSelector,
  StackColumn,
} from '@ocodelib/mui';
import { isBlank, isNotBlank, trim } from '@ocodelib/util/string';
import { PasswordTextField } from '@repo-foundation/components/PasswordTextField';
import { LoginProfileLoadedEvent } from '@repo-ui/auth/authentication';
import { useCoppiaPub } from '@repo-ui/provider/CoppiaPubProvider';
import { useApi } from '@repo-ui/provider/useApi';
import { routerPushI18n } from '@repo-ui/router';
import { handleError } from '@repo-ui/util/handle-error';
import { useLocale, useTranslations } from 'next-intl';
import { useCallback, useRef, useState } from 'react';
import { toast } from 'react-toastify';

interface InputData {
  loginId: string;
  pw: string;
}

export function LoginInputForm() {
  const t = useTranslations('ui');
  const locale = useLocale();
  const rootRef = useRef<HTMLElement>(null);
  const api = useApi();
  const coppia = useCoppiaPub();
  const [inputData, setInputData] = useState<Partial<InputData>>({});
  const [loading, setLoading] = useState(false);
  const [loginProcessing, setLoginProcessing] = useState(false);

  const updateInput = (part: Partial<InputData>) => {
    setInputData((prev) => ({ ...prev, ...part }));
  };

  const focus = useCallback((selector: string) => {
    requestFocusSelector(rootRef.current, selector, 0);
  }, []);

  /**
   * 서버 로그인 실행
   */
  const doLogin = useCallback(
    async (loginId: string, password: string, coppia: CoppiaPub): Promise<boolean> => {
      setLoading(true);
      try {
        // const publicKey = await CoppiaUtil.loadPublicKey(coppia.pubKey);
        // 임시로 비번 암호안함
        // const pw = await CoppiaUtil.encryptText(publicKey, password);
        // const cid = coppia.cid,
        const { profile: userProfile } = await api.auth.signIn({
          cid: 0,
          loginId,
          pw: password,
        });
        LoginProfileLoadedEvent.emit({ ...userProfile });
        return true;
      } catch (err) {
        handleError(err);
      } finally {
        setLoading(false);
      }
      return false;
    },
    [api],
  );

  const validateAndLogin = () => {
    // if (!coppia) {
    //   console.warn("coppia is null");
    //   return;
    // }
    setLoginProcessing(true);
    const loginId = trim(inputData.loginId);
    const pw = trim(inputData.pw);
    if (isBlank(loginId)) {
      toast.warn('사용자 ID를 입력해주세요');
      setLoginProcessing(false);
      return false;
    }

    if (isBlank(pw)) {
      toast.warn('비밀번호를 입력해주세요');
      setLoginProcessing(false);
      return;
    }

    doLogin(loginId, pw, coppia as any)
      .then((succ) => {
        if (succ) {
          toast.success('로그인 되었습니다');
          // onLoginSuccess();
          routerPushI18n('/');
        }
      })
      .finally(() => {
        setLoginProcessing(false);
      });
  };

  // 로그인 버튼 클릭
  const handleClickLogin = () => {
    if (loginProcessing) return;
    validateAndLogin();
  };

  // 비밀번호 찾기 버튼 클릭
  const handleClickPwFind = () => {
    toast.warn('비밀번호 찾기 구현 예정');
  };

  // 가입 버튼 클릭
  const handleClickJoin = () => {
    routerPushI18n('/join');
  };

  const { loginId, pw } = inputData;

  return (
    <FlexColumn.Center alignItems="center" className="LoginInputForm-root" ref={rootRef}>
      <Box sx={{ pb: { xs: 5, md: 8 } }}>
        <Typography variant="h4">계정이 있으면? 로그인</Typography>
      </Box>
      <StackColumn alignItems="stretch" spacing={2} sx={{ width: '100%', maxWidth: 500 }}>
        <FlexRow alignItems="center">
          <Typography sx={{ width: 180 }}>아이디 또는 이메일</Typography>
          <TextField
            fullWidth
            label="아이디"
            variant="outlined"
            sx={{ flex: 1 }}
            disabled={loading || loginProcessing}
            size="medium"
            onChange={(e) => {
              updateInput({ loginId: e.target.value });
            }}
            value={loginId ?? ''}
            className="LoginInputForm-loginInput LoginInputForm-loginId"
            onKeyDown={(e) => {
              if (isNotBlank(loginId) && isEnterOrTabKeyEvent(e)) {
                e.preventDefault();
                focus('.LoginInputForm-pw input');
              }
            }}
          />
        </FlexRow>
        <FlexRow alignItems="center" sx={{ position: 'relative', pb: '40px' }}>
          <Typography sx={{ width: 180 }}>비밀번호</Typography>

          <PasswordTextField
            className="LoginInputForm-loginInput LoginInputForm-pw"
            value={pw ?? ''}
            sx={{ flex: 1 }}
            onChange={(e) => {
              updateInput({ pw: e.target.value });
            }}
            onKeyDown={(e) => {
              if (isNotBlank(pw) && isEnterKeyEvent(e)) {
                e.preventDefault();
                focus('.LoginInputForm-loginBtn');
                handleClickLogin();
              }
            }}
          />
          <Link
            href="#!"
            onClick={handleClickPwFind}
            sx={{
              position: 'absolute',
              left: 180,
              bottom: 0,
              textDecoration: 'none',
            }}
          >
            비밀번호를 잊으셨나요?
          </Link>
        </FlexRow>
      </StackColumn>
      <FlexColumn
        alignItems="stretch"
        sx={{
          width: '100%',
          maxWidth: 500,
          mt: 4,
          pl: '180px',
        }}
      >
        <ButtonBase
          className="LoginInputForm-loginBtn"
          disabled={loading || loginProcessing}
          onClick={handleClickLogin}
          sx={{
            display: 'block',
            p: 1,
            minHeight: 48,
            bgcolor: '#8c54b9',
            color: '#fff',
            fontSize: '1.1rem',
            fontWeight: 700,
            borderRadius: 1,
          }}
        >
          {t('login')}
        </ButtonBase>

        <ButtonBase
          className="LoginInputForm-loginBtn"
          disabled={loading || loginProcessing}
          onClick={handleClickJoin}
          sx={{
            mt: 2,
            display: 'block',
            border: '1px solid #000',
            p: 1,
            minHeight: 48,
            bgcolor: '#fff',
            color: '#000',
            fontSize: '1.1rem',
            fontWeight: 700,
            borderRadius: 1,
          }}
        >
          계정 만들기
        </ButtonBase>
      </FlexColumn>
    </FlexColumn.Center>
  );
}
