import { Container, Grid2 } from '@mui/material';
import { Oauth2LoginButtons } from '@repo-ui/components/social';
import { LoginInputForm } from './components/LoginInputForm';

export function LoginFeature() {
  return (
    <Container
      className="LoginFeature-root"
      sx={{
        pt: {
          xs: 3,
          md: 8,
        },
        px: 2,
      }}
    >
      <Grid2 container spacing={3}>
        <Grid2 size={{ xs: 12, md: 6, lg: 7 }}>
          <LoginInputForm />
        </Grid2>
        <Grid2 size={{ xs: 12, md: 6, lg: 5 }}>
          <Oauth2LoginButtons
            sx={{
              mt: {
                xs: 5,
                md: 11,
              },
            }}
          />
        </Grid2>
      </Grid2>
    </Container>
  );
}
