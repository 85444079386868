import { Typography, type SxProps } from '@mui/material';
import { FlexColumn, StackColumn } from '@ocodelib/mui';
import { FacebookOauth2LoginButton } from './FacebookOauth2LoginButton';
import { GithubOauth2LoginButton } from './GithubOauth2LoginButton';
import { GoogleOauth2LoginButton } from './GoogleOauth2LoginButton';

interface Props {
  sx?: SxProps;
}

export function Oauth2LoginButtons(props: Props) {
  const { sx } = props;
  return (
    <FlexColumn.Center alignItems="center" className="Oauth2LoginButtons-root" sx={sx}>
      <StackColumn
        alignItems="stretch"
        spacing={3}
        sx={{
          width: '100%',
          maxWidth: 500,
          '& .Oauth2LoginButtons-btn': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            py: 1.5,
            px: 1,
            fontSize: '1rem',
          },
        }}
      >
        <GoogleOauth2LoginButton
          className="Oauth2LoginButtons-btn"
          sx={{ bgcolor: '#e13937', color: '#fff' }}
        >
          구글 계정으로 계속
        </GoogleOauth2LoginButton>
        <GithubOauth2LoginButton
          className="Oauth2LoginButtons-btn"
          sx={{ bgcolor: '#3c4043', color: '#fff' }}
        >
          깃허브 계정으로 계속
        </GithubOauth2LoginButton>
        <FacebookOauth2LoginButton
          className="Oauth2LoginButtons-btn"
          sx={{ bgcolor: '#2b7cf0', color: '#fff' }}
        >
          Facebook 계정으로 계속
        </FacebookOauth2LoginButton>
      </StackColumn>
      <Typography variant="caption" mt={2}>
        현재는 소셜 로그인이 불가능합니다
      </Typography>
    </FlexColumn.Center>
  );
}
